




































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import EmailInput from '@/components/inputs/EmailInput.vue';

import userx from '@/store/modules/userx';
import { Route, RawLocation } from 'vue-router';
import { EditState } from '@/store/models.def';
import router from '@/router';
import StateButton from '@/components/inputs/StateButton.vue';
import {
  PasswordStrength,
  passwordStrengthRequirement,
} from '../inputs/password.def';
import continueToUrl from '@/helpers/continueToUrl';
import PasswordInput from '../inputs/PasswordInput.vue';
import { OAuthSigninMethod } from '@/store/firestore';
import CrazyGamesSignInButton from '@/components/black/CrazyGamesSignInButton.vue';
import globalx from '@/store/modules/globalx';

@Component({
  components: {
    EmailInput,
    StateButton,
    PasswordInput,
    CrazyGamesSignInButton,
  },
})
export default class Login extends Vue {
  public email = '';
  public password = '';
  public repeatPassword = '';
  public signupError = '';
  public signupErrorCode = '';
  public validateNow = false;
  public loginState: EditState = 'active';

  public passwordStrength = PasswordStrength.Medium;
  public continueUrl = '/';

  get passwordNotSame() {
    if (!this.validateNow) {
      return false;
    }
    return this.password !== this.repeatPassword;
  }
  get passwordRequirement() {
    return this.passwordNotSame
      ? 'Two passwords do not match.'
      : passwordStrengthRequirement[this.passwordStrength];
  }

  public get saveEmailLabel() {
    const labels = {
      active: 'Register',
      sending: 'Registering...',
      done: 'Success',
    };
    return labels[this.loginState];
  }

  // a = (b:any) => this.$store.dispatch('a',b)
  public async signUp() {
    this.validateNow = true;
    if (
      this.email !== null &&
      this.password !== null &&
      this.repeatPassword !== null &&
      this.passwordNotSame === false
    ) {
      this.loginState = 'sending';

      try {
        await userx.createUserWithEmailAndPassword({
          email: this.email,
          password: this.password,
        });
        this.loginState = 'done';
        setTimeout(() => continueToUrl(this.continueUrl), 1000);
      } catch (error) {
        this.loginState = 'active';
        this.signupErrorCode = (error as any).code || '';
        this.signupError =
          (error as any).code === 'auth/email-already-in-use'
            ? 'The email address was registered using non-password method. Try to login using providers below.'
            : (error as any).message || '';
      }
    }
  }

  public async signInAnonymously() {
    this.loginState = 'sending';
    try {
      await userx.signInAnonymously();
      this.loginState = 'done';
      setTimeout(() => continueToUrl(this.continueUrl), 1000);
    } catch (error) {
      this.loginState = 'active';
      this.signupError = (error as any).message || '';
    }
  }
  public async signInWithOAuth(method: OAuthSigninMethod) {
    this.loginState = 'sending';
    try {
      await userx.signInWithOAuth(method);
      this.loginState = 'done';
      setTimeout(() => continueToUrl(this.continueUrl), 1000);
    } catch (error) {
      this.loginState = 'active';
      this.signupError = (error as any).message || '';
    }
  }

  public get pageReady() {
    if (userx.loginStatus === 'loading') {
      return 'auth';
    } else if (userx.loginStatus === 'logged out') {
      return 'done';
    } else if (userx.loginStatus === 'logged in') {
      return 'redirect';
    }
  }
  @Watch('pageReady', { immediate: true })
  public pageReadyChanged(newVal: string) {
    if (newVal === 'auth') {
      this.$root.$emit('loading', 'Authenticating...');
    } else {
      this.$root.$emit('loading', '');
    }
    if (newVal === 'redirect') {
      continueToUrl(this.continueUrl);
    }
  }

  public beforeRouteEnter(
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Login) => any) | void) => void,
  ): void {
    next((vm) => {
      vm.email = to.query.email ? (to.query.email as string) : '';
    });
  }
  public get showCrazyGamesButtons() {
    return globalx.showCrazyGamesButtons;
  }
}
